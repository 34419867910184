import HeroBackground from "../assets/images/background-images/2149837032.jpg";
// import HeroVideoBackground from "../assets/videos/background-video.mp4";
import LogoType2 from "../assets/logo/name.png";
import ArrowDown from "../assets/svg/arrow-down.svg";
import BehindTheScenes from "../Components/SampleTrailers";
import { MoviePosters } from "../Components/MoviePosters";
import { MovieTrailers } from "../Components/MovieTrailers";
import { NewsItems } from "../Components/NewsItems";
import InstagramImage from "../assets/images/instagram-phone.svg";

export const HomePage = () => {
  return (
    <>
      <section id="HeroSection">
        <div className="background">
          {/* <video src={HeroVideoBackground} muted autoPlay loop></video> */}
          <img src={HeroBackground} alt="Success Star Productions" />
        </div>
        <div className="container">
          <div className="imageContainer">
            <img src={LogoType2} alt="" />
          </div>
          <h3>
            Celebrating African stories, voices, and visionaries – bringing
            bold, independent films from Africa to the world.
          </h3>
          <a href="#MovieList" className="bouncingButton">
            <img src={ArrowDown} alt="" />
          </a>
        </div>
      </section>

      <MoviePosters />

      <BehindTheScenes />

      <MovieTrailers />

      <NewsItems />

      <section id="Instagram">
        <a
          href="https://www.instagram.com/motherinlawmovie/"
          className="imageContainer"
        >
          <img src={InstagramImage} alt="" />
        </a>
      </section>
    </>
  );
};
