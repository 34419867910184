import React from "react";
import { MoviePosters } from "../Components/MoviePosters";

export const Movies = () => {
  return (
    <>
      <section>
        <MoviePosters />
      </section>
    </>
  );
};
