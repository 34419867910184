import React from "react";

import backgroundImage from "../../assets/images/movie-posters/Mother-In-Law.jpg";
// import Characters from "../../assets/Mother-In-Law/characters.png";

export const MotherInLaw = () => {
  const movieCast = [
    {
      name: "Patience Ozokwor",
      role: "Mama Sallia",
    },
    {
      name: "Miguel A. Nuñez Jr.",
      role: "Mr. Samuels",
    },
    {
      name: "Adjetey Anang",
      role: "Kumi",
    },
    {
      name: "Ciarra Carter",
      role: "Koya",
    },
    {
      name: "Bukky Wright",
      role: "Madam Kiola",
    },
    {
      name: "Mercy MacJoe",
      role: "Chioma",
    },
    {
      name: "Kenneth J. Morgan",
      role: "Officer Briant",
    },
    {
      name: "Miloh England",
      role: "Kelvin",
    },
    {
      name: "Abrilia Jones",
      role: "Sharon",
    },
    {
      name: "Everton Dennis",
      role: "Kidnapper",
    },
    {
      name: "Francesca Barker McCormmick",
      role: "Melody",
    },
    {
      name: "Prince Joshua Budukuma",
      role: "Jungle Lion",
    },
    {
      name: "Mo Cisse",
      role: "Journalist",
    },
    {
      name: "Luma Ndikum",
      role: "Kidnapper 2",
    },
  ];

  return (
    <>
      <section id="MovieHero">
        <div className="background">
          <img src={backgroundImage} alt="Mother-In-Law The Movie 2024" />
        </div>
        <div className="container">
          <h1 className="movieTitle">Mother-In-Law The Movie 2024</h1>
          <ul className="details">
            <li>
              <i className="ri-calendar-line"></i>
              <span>
                26<sup>th</sup> March, 2025
              </span>
            </li>
            <li>
              <i className="ri-time-line"></i>
              <span>2hr 30min</span>
            </li>
          </ul>
          <a href="" className="trailerButton">
            <i className="ri-play-circle-fill"></i>
            <span>Watch the trailer</span>
          </a>
        </div>
      </section>

      <section id="Synopsis">
        <h2>Synopsis:</h2>
        <p>
          When two lovers are at the crossroad of inevitable dangerous choices,
          will they bend the rules on their own terms or will they allow the
          rules to bend them.
        </p>
      </section>

      <section id="MovieCast">
        <div className="container">
          <h2>Cast</h2>
          <div className="castGallery">
            {movieCast.map((actor, index) => (
              <div className="actor" key={index}>
                <h3>{actor.name}</h3>
                <p>{actor.role}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
