import { useState } from "react";
import "./App.css";
import Logo from "./assets/logo/success_star.png";
import SearchButton from "./assets/svg/search-2-line.svg";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomePage } from "./Pages/HomePage";
import { Movies } from "./Pages/Movies";
import LogoType2 from "./assets/logo/name.png";
import { MotherInLaw } from "./Pages/Movies/MotherInLaw";

function App() {
  const [menuToggle, setMenuToggle] = useState("");

  const toggleMenu = () => {
    if (menuToggle === "") {
      setMenuToggle("active");
    } else {
      setMenuToggle("");
    }
  };

  return (
    <>
      <header>
        <nav className="primaryNavigation">
          <div className="menuToggle" onClick={toggleMenu}>
            <i className="ri-menu-2-line"></i>
          </div>
          <div className={`left ${menuToggle}`}>
            <a href="/" className="logoContainer">
              <img src={Logo} alt="Success Star Productions" />
            </a>
            <ul className="menu">
              <li>
                <a href="/">Home</a>
              </li>
              {/* <li>
                <a href="#">Events</a>
              </li> */}
              <li>
                <a href="/movies">Movies</a>
              </li>
              {/* <li>
                <a href="#">About</a>
              </li> */}
              <li>
                <a href="#contactus">Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="right">
            <div className="searchBar">
              <label htmlFor="search">
                <img src={SearchButton} alt="" />
              </label>
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Search for movies"
              />
            </div>
            <div className="account">
              <p>
                <a href="signin">Sign In </a>|<a href="register"> Register</a>
              </p>
            </div>
          </div>
        </nav>
      </header>

      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/movies" element={<Movies />} />
          <Route path="/movies/motherinlaw" element={<MotherInLaw />} />
        </Routes>
      </Router>

      <footer id="contactus">
        <hr />
        <div className="imageContainer">
          <img src={LogoType2} alt="Success Star Productions" />
        </div>
        <ul className="socialMediaLogos">
          <li>
            <a href="https://www.instagram.com/motherinlawmovie/">
              <i className="ri-instagram-line"></i>
            </a>
          </li>
          <li>
            <a href="">
              <i className="ri-facebook-line"></i>
            </a>
          </li>
          <li>
            <a href="">
              <i className="ri-phone-line"></i>
            </a>
          </li>
          <li>
            <a href="">
              <i className="ri-mail-line"></i>
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default App;
