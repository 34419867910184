import React from "react";

export const NewsItems = () => {
  return (
    <>
      <section id="News">
        <div className="container">
          <a
            href="https://www.jamaicaobserver.com/2024/11/21/everton-dennis-pleased-award-winning-mother-law/"
            className="newsItem"
          >
            <div className="imageContainer">
              <img src="https://www.jamaicaobserver.com/jamaicaobserver/news/wp-content/uploads/sites/4/2024/11/mother-in-law-cast-award-nov21-768x723.jpeg.webp" alt="" />
            </div>
            <div className="content">
              <h5>Everton Dennis pleased with award-winning ‘Mother in Law’</h5>
              <p>
                Mother In Law, a West Africa-funded movie co-starring Jamaican
                actor Everton Dennis, won Best Independent Film Africa at last
                month’s Hollywood African Prestigious Awards (HAPAwards) in Los
                Angeles. <br /> The comedy, which stars Vivica A Fox, Miguel A Nunez
                and Nigerian actress Patience Ozokwor, was directed by Prince
                Joshua Nana Morrison Budukuma of Ghana. He won the Best Director
                in an Independent Film category.
              </p>
            </div>
          </a>
        </div>
      </section>
    </>
  );
};
