// import React from "react";
// import {
//   DotButton,
//   useDotButton,
// } from "./EmblaCarousel/EmblaCarouselDotButton";
// import {
//   PrevButton,
//   NextButton,
//   usePrevNextButtons,
// } from "./EmblaCarousel/EmblaCarouselArrowButtons";
// import useEmblaCarousel from "embla-carousel-react";

// import Video1 from "../assets/videos/background-video.mp4";

// const MovieTrailers = (props) => {
//   const { options } = props;
//   const [emblaRef, emblaApi] = useEmblaCarousel(options);

//   const slides = [
//     {
//       video: Video1,
//     },
//     {
//       video: Video1,
//     },
//     {
//       video: Video1,
//     },
//   ];

//   const { selectedIndex, scrollSnaps, onDotButtonClick } =
//     useDotButton(emblaApi);

//   const {
//     prevBtnDisabled,
//     nextBtnDisabled,
//     onPrevButtonClick,
//     onNextButtonClick,
//   } = usePrevNextButtons(emblaApi);

//   return (
//     <section className="embla" id="MovieTrailers">
//       <div className="embla__viewport" ref={emblaRef}>
//         <div className="embla__container">
//           {slides.map((index) => (
//             <div className="embla__slide" key={index}>
//               {/* <video src={Video1} controls></video> */}
//             </div>
//           ))}
//         </div>
//       </div>

//       <div className="embla__controls">
//         <div className="embla__buttons">
//           <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
//           <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
//         </div>

//         <div className="embla__dots">
//           {scrollSnaps.map((_, index) => (
//             <DotButton
//               key={index}
//               onClick={() => onDotButtonClick(index)}
//               className={"embla__dot".concat(
//                 index === selectedIndex ? " embla__dot--selected" : ""
//               )}
//             />
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default MovieTrailers;

import React from "react";
import Video1 from "../assets/videos/background-video.mp4";

export const MovieTrailers = () => {
  return (
    <>
      <section id="MovieTrailer">
        <div className="container">
            <video src={Video1} controls></video>
        </div>
      </section>
    </>
  );
};
