import React from "react";

// image imports
import MotherInLawPoster from "../assets/images/movie-posters/Mother-In-Law.jpg";

export const MoviePosters = () => {
  const movieList = [
    {
      title: "Mother-In-Law The Movie (2024)",
      image: MotherInLawPoster,
      link: "movies/motherinlaw",
    },
    {
      title: "Stop De Hate - Upcoming",
      image: "",
      link: "movies/stopdehate",
    },
  ];

  return (
    <>
      <section id="MovieList">
        <div className="posterGallery">
          {movieList.map((movie, index) => (
            <a href={movie.link} className="poster" key={index}>
              <div className="imageContainer">
                <img src={movie.image} alt={movie.title} />
              </div>
              <h3>{movie.title}</h3>
            </a>
          ))}
        </div>
      </section>
    </>
  );
};
